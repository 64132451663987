.headerbuttons:hover {
  color: "#FFD700";
}

.headerbuttons {
  color: "red";
}

.backimg {
  overflow: hidden;
  position: relative;
}

.backimg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./resources/homeback.jpg") center center;
  background-repeat: no-repat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.backimgservice:before {
  background: url("./resources/srvice-back.jpg") center center;
  background-repeat: no-repat;
  background-size: cover;
}

.backimgaboutus:before {
  background: url("./resources/aboutus-back.jpg");
  background-position: right top 15%;
  background-size: cover;
  background-repeat: no-repat;
}

.backimgcontact:before {
  background: url("./resources/contact-back.jpg") top center;
  background-size: cover;
  background-repeat: no-repat;
}

.number::after {
  content: counter(count);
  animation: counter linear 0.8s 1 normal forwards;
}

.numberB::after {
  content: counter(countB);
  animation: counterB linear 0.8s 1 normal forwards;
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 1;
  }
  20% {
    counter-increment: count 2;
  }
  30% {
    counter-increment: count 3;
  }
  40% {
    counter-increment: count 4;
  }
  50% {
    counter-increment: count 5;
  }
  60% {
    counter-increment: count 6;
  }
  70% {
    counter-increment: count 7;
  }
  80% {
    counter-increment: count 8;
  }
  90% {
    counter-increment: count 9;
  }
  100% {
    counter-increment: count 10;
  }
}

@keyframes counterB {
  0% {
    counter-increment: countB 0;
  }
  20% {
    counter-increment: countB 1;
  }
  40% {
    counter-increment: countB 2;
  }
  60% {
    counter-increment: countB 3;
  }
  80% {
    counter-increment: countB 4;
  }
  100% {
    counter-increment: countB 5;
  }
}
